<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap>
            <v-col cols="9">
                <!-- Page view -->
                <router-view :key="reloadPage"></router-view>
                <!-- end -->
            </v-col>
            <v-col cols="2">
               
            </v-col>
        </v-layout>
    </v-container>
</template>


<script>
  export default {
    name: 'Users',
    data() {
      return {
        applyFilters: null,
        reloadPage: 0,
        countingAgents: 0
      }
    },

  }
</script>
